import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "react-scroll";

const Norbio = (props) => {
  const data = useStaticQuery(graphql`
  query getNorBio {
    markdownRemark(fileAbsolutePath: { regex: "/norbio.md/" }) {
      html
      frontmatter {
        title
        category
        lang
      }
    }
   }
  `)
  let title = data.markdownRemark.frontmatter.title
  let html = data.markdownRemark.html
  return (
    <div className="basePad flex flexStart column" id="top">
      <h2>{title}</h2>
      <section
          dangerouslySetInnerHTML={{ __html: html }}
          itemProp="articleBody"
        />
      <Link
        to="top"
        alt={`To English bio`}
        smooth={true}
        duration={400}
        className="flex row"
        >
          <div 
            role="button" 
            tabIndex="-1" 
            className="btn--inverse" 
            onClick={props.click} 
            onKeyDown={props.click}>
              <h3>English</h3>
            </div>
        </Link>
    </div>
  )
}
export default Norbio;
