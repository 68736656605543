import React from 'react'
import FrontBio from "../components/FrontBio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const Bio = ({data, location}) => {
  const bioImg = getImage(data.bioImg)

  return (
    <Layout location={location} title="About Magnus">
      <Seo title="Bio" />
      <div className="bio">
        <FrontBio img={bioImg} />
      </div>
    </Layout>
  )
}
export default Bio;
export const bioQuery = graphql`
  query {
     bioImg: file(relativePath: { eq: "Orfeus-bestn2-2021-foto-Erik-Berg-10.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
