import React, {useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Engbio from "./Engbio"
import Norbio from "./Norbio"


const FrontBio = (props) => {
  const [stateLang, setStateLang] = useState('eng')
  let handleClick = () => {
    if(stateLang === "eng"){
      setStateLang("nor")
    } else {
      setStateLang("eng")
    }
  }
  return(
    <div className="frontBio">
      <GatsbyImage image={props.img} alt="" />
      {stateLang === "eng" ? 
      <Engbio click={handleClick} /> : 
      <Norbio click={handleClick} />
      }
    </div>
  )
}
export default FrontBio;
